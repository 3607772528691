

































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import axios from 'axios'
import moment from 'moment'
import { namespace } from 'vuex-class'
import snakeCaseKeys from 'snakecase-keys'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'

import NotificationMixin from '@/mixins/notificationMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

const sessionModule = namespace('session')

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    TrashCan16,
    NotifyOnFailed,
    PasswordConfirmField
  }
})
export default class RegisterActivity extends Mixins(NotificationMixin, FlashMessageMixin, ValidatorConfigMixin) {
  @sessionModule.Getter state!: Record<string, any>
  form: Record<string, any> = {
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  yesterday = moment().subtract(1, 'days').format('DD/MM/YYYY')
  today = moment().format('DD/MM/YYYY')
  myActivities = [
    {
      selectedActivity: {},
      activityId: '',
      typeId: '',
      roleId: '',
      key: 0
    }
  ]

  globalKey = 1
  unchanged = true
  done = true

  @Watch('myActivities', { deep: true })
  onMymyActivitiesChange () {
    this.unchanged = false
  }

  created () {
    this.validations()
    axios.get('service/user/activity')
      .then((response) => {
        const activities: ServiceUserActivities = response.data.data
        activities.forEach(({ id, name, roles, types }: ServiceUserActivity) => {
          this.activities.push({ id, name, roles, types })
        })
      })
  }

  activities: ServiceUserActivities = []

  setActivity (activityId: string, myActivityIndex: number) {
    const activity = this.activities.find(activity => activity.id === activityId)

    if (activity) {
      this.myActivities[myActivityIndex].typeId = ''
      this.myActivities[myActivityIndex].roleId = ''
      this.myActivities[myActivityIndex].selectedActivity = activity
    }
  }

  addActivity () {
    this.myActivities.push({
      selectedActivity: {},
      activityId: '',
      typeId: '',
      roleId: '',
      key: this.globalKey++
    })
  }

  removeActivity (key: number) {
    this.myActivities = this.myActivities.filter((activity) => activity.key !== key)
  }

  submitForm () {
    this.done = false
    const form: Record<string, any> = {
      diaries: [],
      confirm: this.form.confirm
    }

    this.myActivities.forEach(activity => {
      const { roleId, activityId, typeId } = activity
      const dbFormat = 'YYYY-MM-DD HH:mm:ss'
      const row = {
        roleId,
        activityId,
        typeId,
        serviceUserId: this.$route.params.serviceUserId,
        performedAt: this.$route.params.day === 'today' ? moment().format(dbFormat) : moment().add(-1, 'days').format(dbFormat)
      }
      form.diaries.push(snakeCaseKeys(row))
    })

    axios.post(`service/${this.$route.params.serviceUserId}/diary`, form)
      .then((response) => {
        const flashMessage = {
          message: response.data.data,
          isSuccess: true
        }
        this.$router.push({ name: 'ServicesShow', params: { id: this.$route.params.serviceId } }, () => {
          this.setFlashMessage(flashMessage)
        })
      })
      .catch(err => {
        const response = err.response.data

        if (response.message) {
          this.setNotification('error', response.message)
        }

        const errors = response.errors
        if (errors) {
          Object.entries(errors).map(([key, value]: [string, any]) => {
            this.form.errors[key] = value[0]
          })
        }
      })
      .finally(() => { this.done = true })
  }
}
