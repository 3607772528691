var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-8 pb-12"},[_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('router-link',{attrs:{"to":{name: 'ServicesShow', params: {id: _vm.$route.params.serviceId}}}},[_c('cv-button',{staticClass:"whitespace-no-wrap",attrs:{"kind":"secondary"}},[_vm._v(" Voltar para o Centro de Serviço ")])],1)],1),_c('div',{staticClass:"mt-6"},[_c('div',{staticClass:"text-3xl"},[_vm._v(" Registrar Atividades do dia "+_vm._s(_vm.$route.params.day === 'today' ? _vm.today : _vm.yesterday)+" ")]),_c('ValidationObserver',{staticClass:"mt-12 md:mx-12",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('transition-group',{attrs:{"name":"list-complete","tag":"div"}},_vm._l((_vm.myActivities),function(myActivity,index){return _c('div',{key:myActivity.key,staticClass:"list-complete-item flex flex-col md:flex-row pt-8"},[_c('div',{staticClass:"w-full md:w-1/4 flex"},[_c('div',{staticClass:"self-end",on:{"click":function($event){return _vm.removeActivity(myActivity.key)}}},[_c('cv-button',{staticClass:"bx--btn--icon-only",attrs:{"kind":"secondary"}},[_c('TrashCan16',{staticClass:"bx--btn__icon"})],1)],1),_c('ValidationProvider',{staticClass:"ml-3 w-full",attrs:{"tag":"div","rules":"required","name":("atividade " + (index + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-dropdown',{attrs:{"label":"Atividade","placeholder":"Selecione a atividade","invalid-message":errors[0]},on:{"change":function($event){return _vm.setActivity(myActivity.activityId, index)}},model:{value:(myActivity.activityId),callback:function ($$v) {_vm.$set(myActivity, "activityId", $$v)},expression:"myActivity.activityId"}},_vm._l((_vm.activities),function(ref){
var id = ref.id;
var name = ref.name;
return _c('cv-dropdown-item',{key:id,attrs:{"value":id}},[_vm._v(" "+_vm._s(name)+" ")])}),1)]}}],null,true)})],1),_c('div',{staticClass:"w-full pt-4 md:w-1/2 md:pl-4 md:pt-0"},[(Object.keys(myActivity.selectedActivity).length)?_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":("tipo " + (index + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-dropdown',{attrs:{"label":"Tipo","placeholder":"Selecione o tipo","invalid-message":errors[0]},model:{value:(myActivity.typeId),callback:function ($$v) {_vm.$set(myActivity, "typeId", $$v)},expression:"myActivity.typeId"}},[(myActivity.typeId)?_c('cv-dropdown-item',{attrs:{"value":""}},[_vm._v(" Selecione o tipo ")]):_vm._e(),_vm._l((myActivity.selectedActivity.types),function(type){return _c('cv-dropdown-item',{key:type.id,attrs:{"value":type.id}},[_vm._v(" "+_vm._s(type.name)+" ")])})],2)]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"w-full pt-4 md:w-1/4 md:pl-4 md:pt-0"},[(Object.keys(myActivity.selectedActivity).length && myActivity.selectedActivity.roles.length)?_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":("função " + (index + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-dropdown',{attrs:{"label":"Função Principal","placeholder":"Selecione a função","invalid-message":errors[0]},model:{value:(myActivity.roleId),callback:function ($$v) {_vm.$set(myActivity, "roleId", $$v)},expression:"myActivity.roleId"}},[(myActivity.roleId)?_c('cv-dropdown-item',{attrs:{"value":""}},[_vm._v(" Selecione o tipo ")]):_vm._e(),_vm._l((myActivity.selectedActivity.roles),function(role){return _c('cv-dropdown-item',{key:role.id,attrs:{"value":role.id}},[_vm._v(" "+_vm._s(role.name)+" ")])})],2)]}}],null,true)}):_vm._e()],1),_c('hr')])}),0),_c('div',{staticClass:"w-full md:w-1/4 pt-4"},[_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1),(_vm.notification.open)?_c('cv-inline-notification',{staticClass:"mt-12",attrs:{"kind":_vm.notification.kind,"title":_vm.notification.title,"sub-title":_vm.notification.message,"close-aria-label":"Fechar","low-contrast":false},on:{"close":function($event){_vm.notification.open = false}}}):_vm._e(),_c('div',{staticClass:"flex flex-col md:flex-row w-full mt-8"},[_c('cv-button',{attrs:{"kind":"tertiary"},on:{"click":_vm.addActivity}},[_vm._v(" Acrescentar Atividade ")]),_c('div',{staticClass:"mt-4 md:ml-4 md:mt-0"},[(!_vm.unchanged && _vm.done)?_c('cv-button',{on:{"click":function($event){_vm.syncTimeout(reset, function () { return handleSubmit(_vm.submitForm); })}}},[_vm._v(" Salvar Alterações ")]):(!_vm.done)?_c('cv-button-skeleton'):_vm._e()],1)],1),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }